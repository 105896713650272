<template>
	<div>
		<p>
			This domain name is registered for a client of Becurious.<br />
			For more information get in touch via <a href="mailto:info@becurious.com">info@becurious.com</a>
		</p>
		<a class="button" href="https://www.becurious.com" target="_blank">Visit our website</a>
	</div>
</template>

<script>
export default {
	name: 'IndexPage',
};
</script>

<style lang="scss" scoped>
.button {
	padding: 6px 15px;
	display: inline-block;
	float: none;
	text-decoration: none;
	color: #fff;
	background: #0c9;
	border-radius: 5px;
	margin-top: 5px;

	&:hover {
		background: #04e6ae;
		color: #fff;
	}
}
</style>
